
export default {
  namespaced: true,

  state: {
    count: 0,
    current_step_data: [],
    update_bank_details: false,
    basic_information: {},
    employment_information: {},
    bank_information: {},
    identity_info: {},
    uploaded_document: [],
    update_bank_details_status: '',
    // loan_details: {
    //   lead_creation: "",
    //   borrower_name: "",
    //   mobile_no: "",
    //   email_id: "",
    //   borrower_initiated_by: "",
    //   borrower_added_by_teammember: "",
    //   created: "",
    //   created_time: "",
    //   loan_amount: null,
    //   tenure: "",
    //   product_type: {},
    //   scheme: {},
    //   // product_type: {
    //   //   id: null,
    //   //   name: "",
    //   // },
    //   // scheme: {
    //   //   name: "",
    //   //   code: "",
    //   //   type: "",
    //   // },
    // },
    loan_details: [],
  },

  getters: {
    getCurrentStepData: (state) => state.current_step_data,
    getCount: (state) => state.count,

    getLeadDetails: (state) => state,
    getBasicInfo: (state) => state.basic_information,
    getEmploymentInfo: (state) => state.employment_information,
    getBankDetails: (state) => state.bank_information,
    getIdentity: (state) => state.identity_info,
    getUploadedDocument: (state) => state.uploaded_document,
    getLoanDetails: (state) => state.loan_details,
    getUpdateBankDetails: (state) => state.update_bank_details,
    getStatusUpdateBankDetails: (state) => state.update_bank_details_status,
  },

  mutations: {
    SET_CURRENT_STEP_DATA(state, obj) {
      state.current_step_data = obj;
    },
    SET_COUNT(state) {
      state.count += 1;
    },

    SET_BASIC_INFO(state, obj) {
      state.basic_information = obj;
    },
    SET_EMPLOYMENT_INFO(state, obj) {
      state.employment_information = obj;
    },
    SET_BANK_INFO(state, obj) {
      state.bank_information = obj;
    },
    SET_DOCUMENTS_INFO(state, obj) {
      state.identity_info = obj;
    },
    SET_BS_DOCUMENTS_INFO(state, obj) {
      state.uploaded_document = obj;
    },
    SET_LOAN_DETAILS(state, obj) {
      state.loan_details = obj;
    },
    SET_UPDATE_BANK_DETAILS(state, obj) {
      state.update_bank_details = obj;
    },
    SET_STATUS_UPDATE_BANK_DETAILS(state, obj) {
      state.update_bank_details_status = obj
    }
  },

  actions: {
    setCurrentStepData({ commit }, obj) {
      commit("SET_CURRENT_STEP_DATA", obj);
    },
    setCount({ commit }) {
      commit("SET_COUNT");
    },
    setBasicInfo({ commit }, obj) {
      commit("SET_BASIC_INFO", obj);
    },
    setEmployementDetails({ commit }, obj) {
      commit("SET_EMPLOYMENT_INFO", obj);
    },
    setBankDetails({ commit }, obj) {
      commit("SET_BANK_INFO", obj);
    },
    setIdentityInfo({ commit }, obj) {
      commit("SET_DOCUMENTS_INFO", obj);
    },
    setBsIdentityInfo({ commit }, obj) {
      commit("SET_BS_DOCUMENTS_INFO", obj);
    },
    setLoanDetails({ commit }, obj) {
      commit("SET_LOAN_DETAILS", obj);
    },
    setUpdateBankDetails({ commit }, obj) {
      commit("SET_UPDATE_BANK_DETAILS", obj)
    },
    setStatusUpdateBankDetails({ commit }, obj) {
      commit("SET_STATUS_UPDATE_BANK_DETAILS", obj)
    }
  },
};
