import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: { requiresAuth: false, title: "Login", show_back_btn: false },
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/LoginPage/Login.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () =>
      import(
        /* webpackChunkName: "BaseLayout" */ "@/components/layouts/Base.vue"
      ),
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
    children: [
      // {
      //     path: 'dashboard',
      //     name: 'dashboard',
      //     meta: { requiresAuth: true, title: "Dashboard", show_back_btn: false },
      //     component: () => import( /* webpackChunkName: "Dashboard" */ "@/views/Dashboard/index.vue")
      // },
      {
        path: "team",
        name: "team",
        meta: {
          requiresAuth: true,
          title: "Team",
          show_back_btn: false,
          tab_title: "Team",
        },
        component: () =>
          import(/* webpackChunkName: "Team" */ "@/views/Admin/team/index.vue"),
      },
      {
        path: "team/details/:id",
        name: "team-details",
        meta: {
          requiresAuth: true,
          title: "Team Member Details",
          show_back_btn: true,
          tab_title: "Team",
        },
        component: () =>
          import(
            /* webpackChunkName: "Team" */ "@/views/Admin/team/ViewMemberDetails.vue"
          ),
      },
      {
        path: "team/add",
        name: "add-team",
        meta: {
          requiresAuth: true,
          title: "Add Team Member",
          show_back_btn: true,
          tab_title: "Team",
        },
        component: () =>
          import(
            /* webpackChunkName: "Team" */ "@/views/Admin/team/AddTeamMember.vue"
          ),
      },
      {
        path: "loan-scheme",
        name: "loan-scheme",
        meta: {
          requiresAuth: true,
          title: "Loan Schema",
          show_back_btn: false,
          tab_title: "Loan Schema",
        },
        component: () =>
          import(
            /* webpackChunkName: "LoanSchema" */ "@/views/Admin/LoanScheme/index.vue"
          ),
      },
      {
        path: "loan-scheme/details/:id",
        name: "loan-scheme-details",
        meta: {
          requiresAuth: true,
          title: "Loan Schema",
          show_back_btn: true,
          tab_title: "Loan Schema",
        },
        component: () =>
          import(
            /* webpackChunkName: "LoanSchema" */ "@/views/Admin/LoanScheme/ViewLoanSchemeDetails.vue"
          ),
      },
      {
        path: "loan-scheme/add",
        name: "add-loan-scheme",
        meta: {
          requiresAuth: true,
          title: "Loan Schema",
          show_back_btn: true,
          tab_title: "Loan Schema",
        },
        component: () =>
          import(
            /* webpackChunkName: "LoanSchema" */ "@/views/Admin/LoanScheme/AddLoanSchemeDetails.vue"
          ),
      },
      {
        path: "lead-list",
        name: "lead-list",
        meta: {
          requiresAuth: true,
          title: "LOS",
          show_back_btn: false,
          tab_title: "LOS",
        },
        component: () =>
          import(/* webpackChunkName: "LOS" */ "@/views/LOS/index.vue"),
      },
      {
        path: "lead-list/details/:id",
        name: "lead-details",
        meta: {
          requiresAuth: true,
          title: "Lead Details",
          show_back_btn: true,
          tab_title: "LOS",
        },
        component: () =>
          import(/* webpackChunkName: "LOS" */ "@/views/LOS/LeadDetails.vue"),
      },
      // {
      //   path: "lead-list/add",
      //   name: "add-lead-list",
      //   meta: {
      //     requiresAuth: true,
      //     title: "Create Lead",
      //     show_back_btn: true,
      //     tab_title: "LOS",
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "team" */ "@/views/LOS/CreateLead.vue"),
      // },
      {
        path: "lead-list/add/:loan_type_id/:loan_type",
        name: "add-lead-list",
        meta: {
          requiresAuth: true,
          title: "Create",
          show_back_btn: true,
          tab_title: "LOS",
        },
        component: () =>
          import(/* webpackChunkName: "LOS" */ "@/views/LOS/createNewLead.vue"),
      },
      {
        path: "lms-list",
        meta: {
          requiresAuth: true,
          title: "LMS",
          show_back_btn: true,
          tab_title: "LMS",
        },
        component: () =>
          import(/* webpackChunkName: "LMS" */ "@/views/LMS/index.vue"),

        children: [
          {
            path: "/",
            name: "lms-List",
            meta: {
              requiresAuth: true,
              title: "LMS",
              show_back_btn: true,
              tab_title: "LMS",
            },
            component: () =>
              import(
                /* webpackChunkName: "LMS" */ "@/components/lms/LmsList.vue"
              ),
          },
          {
            path: "details/:id/:mobile",
            name: "lms-details",
            meta: {
              requiresAuth: true,
              title: "LMS Details",
              show_back_btn: true,
              tab_title: "LMS",
            },
            component: () =>
              import(
                /* webpackChunkName: "LMS" */ "@/views/LMS/LmsDetails.vue"
              ),
          },
        ],
      },
      // {
      //   path: "/lms-list/lms-details/:id/:mobile",
      //   name: "lms-details",
      //   meta: {
      //     requiresAuth: true,
      //     title: "LMS Details",
      //     show_back_btn: true,
      //     tab_title: "LMS",
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "team" */ "@/views/LMS/LmsDetails.vue"),
      // },

      {
        path: "account-list",
        name: "account-list",
        meta: {
          requiresAuth: true,
          title: "Disbursement",
          show_back_btn: false,
          tab_title: "Disbursement",
        },
        component: () =>
          import(
            /* webpackChunkName: "Accounts" */ "@/views/Accounts/index.vue"
          ),
      },
      {
        path: "account-list/disbursement/:id/:status",
        name: "account_disbursement",
        meta: {
          requiresAuth: true,
          title: "Disbursement",
          show_back_btn: true,
          tab_title: "Disbursement",
        },
        component: () =>
          import(
            /* webpackChunkName: "Disbursement" */ "@/views/Accounts/Disbursement/Disbursement.vue"
          ),
      },
      {
        path: "collection-list",
        name: "collection-list",
        meta: {
          requiresAuth: true,
          title: "Collection",
          show_back_btn: false,
          tab_title: "Collection",
        },
        component: () =>
          import(
            /* webpackChunkName: "Collections" */ "@/views/Collections/index.vue"
          ),
      },
      {
        path: "collection-list/contacts/:id",
        name: "collection-contacts",
        meta: {
          requiresAuth: true,
          title: "Contacts",
          show_back_btn: true,
          tab_title: "Collection of EMI",
        },
        component: () =>
          import(
            /* webpackChunkName: "Collections" */ "@/views/Collections/contacts/contacts.vue"
          ),
      },
      {
        path: "notice-management",
        name: "notice-management",
        meta: {
          requiresAuth: true,
          title: "Notice Management",
          show_back_btn: false,
          tab_title: "Notice Management",
        },
        component: () =>
          import(
            /*webpackChunkName: "NoticeManagement" */ "@/views/NoticeManagement/index.vue"
          ),
      },
      {
        path: "notice-management/details/:id",
        name: "notice_details",
        meta: {
          requiresAuth: true,
          title: "Notice Details",
          show_back_btn: true,
          tab_title: "Notice Management",
        },
        component: () =>
          import(
            /* webpackChunkName: "NoticeManagement" */ "@/views/NoticeManagement/ViewNotice.vue"
          ),
      },
      {
        path: "notice-management/editdetails/:id",
        name: "edit_details",
        meta: {
          requiresAuth: true,
          title: "Edit Notice",
          show_back_btn: true,
          tab_title: "Notice Management",
        },
        component: () =>
          import(
            /* webpackChunkName: "NoticeManagement" */ "@/views/NoticeManagement/EditNotice.vue"
          ),
      },
      {
        path: "session-history",
        name: "session_history",
        meta: {
          requiresAuth: true,
          title: "Session History",
          show_back_btn: false,
          tab_title: "Session History",
        },
        component: () =>
          import(
            /* webpackChunkName: "SessionHistory" */ "@/views/SessionHistory/sessionHistory.vue"
          ),
      },
      {
        path: "session-history/details/:id",
        name: "session_details",
        meta: {
          requiresAuth: true,
          title: "Session Detail",
          show_back_btn: true,
          tab_title: "Session History",
        },
        component: () =>
          import(
            /* webpackChunkName: "SessionHistory" */ "@/components/SessionHistory/sessionDetail.vue"
          ),
      },
      {
        path: "ledger-panel",
        name: "ledger-panel",
        meta: {
          requiresAuth: true,
          title: "Accounts",
          show_back_btn: false,
          tab_title: "Accounts",
        },
        component: () =>
          import(/* webpackChunkName: "Ledger" */ "@/views/Ledger/index.vue"),
      },
      {
        path: "payments",
        name: "payments-panel",
        meta: {
          requiresAuth: true,
          title: "Payments",
          show_back_btn: false,
          tab_title: "Payments",
        },
        component: () =>
          import(
            /* webpackChunkName: "Payment" */ "@/views/Payments/index.vue"
          ),
      },
      {
        path: "dealer",
        name: "dealer",
        meta: {
          requiresAuth: true,
          title: "Dealer",
          show_back_btn: false,
          tab_title: "Dealer",
        },
        component: () =>
          import(
            /* webpackChunkName: "Dealer" */ "@/views/Admin/dealer/index.vue"
          ),
      },
      {
        path: "dealer/details/:id",
        name: "dealer-details",
        meta: {
          requiresAuth: true,
          title: "Dealer Details",
          show_back_btn: true,
          tab_title: "Dealer",
        },
        component: () =>
          import(
            /* webpackChunkName: "Dealer" */ "@/views/Admin/dealer/ViewDealerDetails.vue"
          ),
      },
      {
        path: "dealer/add",
        name: "add-dealer",
        meta: {
          requiresAuth: true,
          title: "Add Dealer",
          show_back_btn: true,
          tab_title: "Dealer",
        },
        component: () =>
          import(
            /* webpackChunkName: "Dealer" */ "@/views/Admin/dealer/AddDealer.vue"
          ),
      },
      {
        path: "reports",
        name: "reports-panel",
        meta: {
          requiresAuth: true,
          title: "Reports",
          show_back_btn: false,
          tab_title: "Reports",
        },
        component: () =>
          import(/* webpackChunkName: "Reports" */ "@/views/Reports/index.vue"),
      },
      {
        path: "create-report-template",
        name: "create-report-template",
        meta: {
          requiresAuth: true,
          title: "Create Report Template",
          show_back_btn: true,
          tab_title: "Reports",
        },
        component: () =>
          import(
            /* webpackChunkName: "Reports" */ "@/views/Reports/CreateTemplate.vue"
          ),
      },
      {
        path: "generate-report",
        name: "generate-report",
        meta: {
          requiresAuth: true,
          title: "Generate Report",
          show_back_btn: true,
          tab_title: "Reports",
        },
        component: () =>
          import(
            /* webpackChunkName: "Reports" */ "@/views/Reports/GenerateReport.vue"
          ),
      },
    ],
  },
  {
    path: "/image-viewer/:document?/:image?",
    name: "image-viewer",
    meta: { requiresAuth: false, title: "Image Viewer", show_back_btn: false },
    component: () =>
      import(
        /* webpackChunkName: "ImageViewer" */ "@/components/common/ImageViewer.vue"
      ),
  },
  {
    path: "/complete-finvu-detail/:doc_id",
    name: "finvu-detail",
    meta: {
      requiresAuth: false,
      title: "Complete Finvu Detail",
      show_back_btn: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "ImageViewer" */ "@/components/los/LoanDetails/CompleteFinvuDetails.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/* redirecting to home page if access token is not provided*/
// router.beforeEach((to, from, next) => {

//     let access_token = sessionStorage.getItem("token")
//     // console.log(access_token);
//     if (to.meta.requiresAuth == true && !access_token) {
//         next({
//             name: 'login'
//         })
//     } else if (to.meta.requiresAuth == true && access_token) {
//         next({
//             name: 'team'
//         })
//     } else
//         next()

// });

const guard = function (to, from, next) {
  if (sessionStorage.getItem("token")) {
    next();
  } else if (sessionStorage.getItem("token") == "" && next("/")) {
    next("/");
  } else {
    next("/");
  }
};

// router.beforeEach((to, from, next) => {

//     let access_token = sessionStorage.getItem("token")
//     if (to.meta.requiresAuth == true && !access_token) {
//         next({
//             name: 'login'
//         })
//     } else if (to.meta.requiresAuth == false && access_token) {
//         next({
//             name: 'team'
//         })
//     } else
//         next()

// });

export default router;
