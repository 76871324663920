export default {
  namespaced: true,

  state: {
    stepper_id: 1,
    business_stepper_id: 1,
    vehicle_stepper_id: 1,
    business_entity_type: "",
    lead_creation: "",
    existing_lead_creation_id: "",
    business_loan_lead_creation: "",
    // business_loan_new_lead_creation: "LD256",
    is_existing_customer: true,
    basic_information: {
      basic_details: {
        borrower_name: "",
        guardian_name: "",
        mobile_no: "",
        email_id: "",
        gender: "",
        dob: "",
        marital_status: "",
        highest_degree: "",
        institute: "",
      },
      current_add: {
        resident_type: "Own",
        address_line_one: "",
        address_line_two: "",
        pincode: "",
        city: "",
        state: "",
        is_permanent_address: false,
        same_as_aadhar: false,
      },
      permanent_add: {
        is_permanent_address: true,
        resident_type: "Rented",
        address_line_one: "",
        address_line_two: "",
        pincode: "",
        city: "",
        state: "",
      },
      reference_info: [
        {
          name: "",
          relationship_type: "",
          contact_number: "",
          to_be_call: true,
          is_friend: false,
        },
        {
          name: "",
          contact_number: "",
          relationship_type: "",
          to_be_call: true,
          is_friend: false,
        },
        {
          name: "",
          contact_number: "",
          relationship_type: "",
          to_be_call: true,
          is_friend: true,
        },
        {
          name: "",
          contact_number: "",
          relationship_type: "",
          to_be_call: true,
          is_friend: true,
        },
      ],
    },
    employment_information: {
      employment_type: "Self Employed",
      salary_type: "Direct Account Transfer",
      company_name: "",
      complete_company_address: "",
      designation: "",
      work_experience_in_year: null,
      work_experience_in_months: null,
      monthly_income: null,
      company_email_id: "",
    },

    bank_information: {
      bank_name: "",
      account_number: "",
      IFSC_code: "",
      account_holder_name: "",
    },

    identity_info: {
      aadhar_card_front_file: "",
      aadhar_number: "",
      pan_number: "",
    },
    uploaded_document: [
      {
        document_type: "",
        uploaded_file: "",
        uploaded_url: "",
        document_password: "",
      },
    ],
  },

  getters: {
    getStepperId: (state) => state.stepper_id,
    getBusinessStepperId: (state) => state.business_stepper_id,
    getVehicleStepperId: (state) => state.vehicle_stepper_id,
    getBusinessEntityType: (state) => state.business_entity_type,
    getLeadCreation: (state) => state.lead_creation,
    getBusinessLeadCreation: (state) => state.business_loan_lead_creation,
    // getBusinessNewLeadCreation: (state) =>
    //   state.business_loan_new_lead_creation,
    getLeadDetails: (state) => state,
    getBasicInfo: (state) => state.basic_information,
    getEmploymentInfo: (state) => state.employment_information,
    getBankDetails: (state) => state.bank_information,
    getIdentity: (state) => state.identity_info,
    getUploadedDocument: (state) => state.uploaded_document,
    getExistingLeadCreationId: (state) => state.existing_lead_creation_id,
    getIsExistingCustomer: (state) => state.is_existing_customer,
  },

  mutations: {
    SET_STEPPER_ID(state, value) {
      state.stepper_id = value;
    },
    SET_BUSINESS_STEPPER_ID(state, value) {
      state.business_stepper_id = value;
    },
    SET_VEHICLE_STEPPER_ID(state, value) {
      state.vehicle_stepper_id = value;
    },
    SET_BUSINESS_ENTITY_TYPE(state, value) {
      state.business_entity_type = value;
    },
    SET_LEAD_CREATION(state, value) {
      state.lead_creation = value;
    },
    SET_BUSINESS_LEAD_CREATION(state, value) {
      state.business_loan_lead_creation = value;
    },
    SET_LEAD_DETAILS: (state, obj) => {
      state.basic_information.customer_id = obj.customer_id;
      state.basic_information.borrower_name = obj.borrower_name;
      state.basic_information.guardian_name = obj.guardian_name;
      state.basic_information.gender = obj.gender;
      state.basic_information.dob = obj.dob;
      state.basic_information.marital_status = obj.marital_status;
      state.basic_information.mobile_no = obj.mobile_no;
      state.basic_information.email_id = obj.email_id;
      state.basic_information.highest_degree = obj.highest_degree;
      state.basic_information.institute = obj.institute;
    },
    SET_BASIC_INFO(state, obj) {
      state.basic_information.customer_id = obj.customer_id;
      state.basic_information.borrower_name = obj.borrower_name;
      state.basic_information.guardian_name = obj.guardian_name;
      state.basic_information.gender = obj.gender;
      state.basic_information.dob = obj.dob;
      state.basic_information.marital_status = obj.marital_status;
      state.basic_information.mobile_no = obj.mobile_no;
      state.basic_information.email_id = obj.email_id;
      state.basic_information.highest_degree = obj.highest_degree;
      state.basic_information.institute = obj.institute;
    },
    SET_EMPLOYMENT_INFO(state, obj) {
      state.employment_information.company_name = obj.company_name;
      state.employment_information.designation = obj.designation;
      state.employment_information.company_address = obj.company_address;
      state.employment_information.monthly_income = obj.monthly_income;
      state.employment_information.work_exp_year = obj.work_exp_year;
      state.employment_information.work_exp_month = obj.work_exp_month;
      state.employment_information.email_id = obj.email_id;
      state.employment_information.employment_type = obj.employment_type;
      state.employment_information.salary_type = obj.salary_type;
    },
    SET_BANK_INFO(state, obj) {
      state.bank_information.bank_name = obj.bank_name;
      state.bank_information.account_number = obj.account_number;
      state.bank_information.IFSC_code = obj.IFSC_code;
      state.bank_information.ac_holder_name = obj.ac_holder_name;
      state.bank_information.account_type = obj.account_type;
      state.bank_information.branch_name = obj.branch_name;
      state.bank_information.is_bank_account_primary =
        obj.is_bank_account_primary;
    },
    SET_DOCUMENTS_INFO(state, obj) {
      state.identity_info.aadhar_card_front_file = obj.aadhar_card_front_file;
      state.identity_info.aadhar_card_back_file = obj.aadhar_card_back_file;
      state.identity_info.pan_card_file = obj.pan_card_file;
      state.identity_info.aadhar_number = obj.aadhar_number;
      state.identity_info.pan_number = obj.pan_number;
      state.identity_info.address_proof_file = obj.address_proof_file;
      state.identity_info.upload_photo_file = obj.upload_photo_file;
      state.identity_info.cibil_report_file = obj.cibil_report_file;
    },
    SET_BS_DOCUMENTS_INFO(state, obj) {
      state.uploaded_document.uploaded_url = obj.uploaded_url;
      state.uploaded_document.uploaded_file = obj.uploaded_file;
      state.uploaded_document.document_type = obj.document_type;
      state.uploaded_document.document_password = obj.document_password;
    },
    SET_EXISTING_LEAD_CREATION_ID(state, value) {
      state.existing_lead_creation_id = value;
    },
    // SET_BUSINESS_NEW_LEAD_CREATION(state, value) {
    //   state.business_loan_new_lead_creation = value;
    // },
    SET_IS_EXISTING_CUSTOMER(state, value){
      state.is_existing_customer = value
    }
  },

  actions: {
    setStepperId({ commit }, value) {
      commit("SET_STEPPER_ID", value);
    },
    setBusinessStepperId({ commit }, value) {
      commit("SET_BUSINESS_STEPPER_ID", value);
    },
    setVehicleStepperId({ commit }, value) {
      commit("SET_VEHICLE_STEPPER_ID", value);
    },
    setBusinessEntityType({ commit }, value) {
      commit("SET_BUSINESS_ENTITY_TYPE", value);
    },
    setBusinessLeadCreation({ commit }, value) {
      commit("SET_BUSINESS_LEAD_CREATION", value);
    },
    setLeadCreation({ commit }, value) {
      commit("SET_LEAD_CREATION", value);
    },
    setBasicInfo({ commit }, obj) {
      commit("SET_BASIC_INFO", obj);
    },
    setLeadDetails({ commit }, obj) {
      commit("SET_LEAD_DETAILS", obj);
    },
    setEmployementDetails({ commit }, obj) {
      commit("SET_EMPLOYMENT_INFO", obj);
    },
    setBankDetails({ commit }, obj) {
      commit("SET_BANK_INFO", obj);
    },
    setIdentityInfo({ commit }, obj) {
      commit("SET_DOCUMENTS_INFO", obj);
    },
    setBsIdentityInfo({ commit }, obj) {
      commit("SET_BS_DOCUMENTS_INFO", obj);
    },
    setExistingLeadCreationId({ commit }, id) {
      commit("SET_EXISTING_LEAD_CREATION_ID", id);
    },
    // setBusinessSSNewLeadCreation({ commit }, id) {
    //   commit("SET_BUSINESS_NEW_LEAD_CREATION", id);
    // },
    setIsExistingCustomer({commit}, value){
      commit("SET_IS_EXISTING_CUSTOMER", value)
    }
  },
};
