export default {
  namespaced: true,
  state: {
    page: 1,
    lead_status: "Applied Loan",
    selected_tab: 0,
    display_daterange: "",
    date_range: [],
    scheme: "",
    city: "",
    executive_type: "",
    executive_name: "",
    overdue_days: "",
    due_installments: "",
    product_type: "",
  },

  mutations: {
    SET_FILTER(
      state,
      {
        page,
        lead_status,
        selected_tab,
        display_daterange,
        date_range,
        product_type,
      }
    ) {
      state.page = page;
      state.lead_status = lead_status;
      state.selected_tab = selected_tab;
      state.display_daterange = display_daterange;
      state.date_range = date_range;
      state.product_type = product_type;
    },
  },
  actions: {
    setFilters({ commit }, items) {
      commit("SET_FILTER", items);
    },
  },
  getters: {
    getFilters: (state) => {
      return state;
    },
  },
};
